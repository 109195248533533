<template> 
    <v-row style='margin-top:20px'>
        <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
            <div class="card card-teal" v-if='data' data-aos="fade">
                <div class="card_header">
                    <span class="float-left">EGMs</span>
                    <span></span>
                </div>
                <div class="table_container gen_table small_pad">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr class='row_blue'>
                                    <td>Total Connected</td>
                                    <td class='align-end'>{{data.egm.totalConnected}}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr class='row_green'>
                                    <td>Total Available</td>
                                    <td class='align-end'>{{data.egm.totalAvailable}}</td>
                                    <td class='align-end'>{{(data.egm.totalAvailable*100/data.egm.totalConnected).toFixed(2)+'%'}}</td>
                                    <td class='align-end'><span class='active_pills'>Active</span></td>
                                </tr>
                                <tr>
                                    <td>Remote</td>
                                    <td class='align-end'>{{data.egm.remote}}</td>
                                    <td class='align-end'>{{(data.egm.remote*100/data.egm.totalConnected).toFixed(2)+'%'}}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Live</td>
                                    <td class='align-end'>{{data.egm.live}}</td>
                                    <td class='align-end'>{{(data.egm.live*100/data.egm.totalConnected).toFixed(2)+'%'}}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Open</td>
                                    <td class='align-end'>{{data.egm.open}}</td>
                                    <td class='align-end'>{{(data.egm.open*100/data.egm.totalConnected).toFixed(2)+'%'}}</td>
                                    <td></td>
                                </tr>
                                <tr class='row_red'>
                                    <td>Out of Service</td>
                                    <td class='align-end'>{{data.egm.outOfService}}</td>
                                    <td class='align-end'>{{(data.egm.outOfService*100/data.egm.totalConnected).toFixed(2)+'%'}}</td>
                                    <td></td>
                                </tr>
                                <tr class='row_lightBlue'>
                                    <td>Remote Play</td>
                                    <td class='align-end'>{{data.egm.remotePlay}}</td>
                                    <td class='align-end'>{{(data.egm.remotePlay*100/data.egm.totalConnected).toFixed(2)+'%'}}</td>
                                    <td></td>
                                </tr>
                            </tbody> 
                        </template>
                    </v-simple-table>
                </div>
            </div>
        </v-col>
        <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
            <div class="card card-teal" v-if='data' data-aos="fade">
            <div class="card_header">
                <span class="float-left">Results</span>
                <span></span>
            </div>
            <div class="table_container gen_table small_pad">
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr class='row_blue'>
                                <td>Total Bet</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.totalBet)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.averageBetPerGame)'></td>
                                <td class='italic'>(avg. bet)</td>
                            </tr>
                            <tr :class='(data.results.result.value<0) ? "row_red" : "row_greenBg"'>
                                <td>Result</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.result)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.actualRTP,true)'></td>
                                <td class='italic'>(actual %)</td>
                            </tr>
                            <tr>
                                <td>No of Games</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.gamesPlayed)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.averageResultPerGame)'></td>
                                <td class='italic'>(result/game)</td>
                            </tr>
                            <tr>
                                <td>Drop</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.totalDrop)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.averageDropPerMachine)'></td>
                                <td class='italic'>(avg/EGM)</td>
                            </tr>
                            <tr>
                                <td>Duration</td>
                                <td class='align-end' v-html='checkTypeDuration(data.results.totalDuration)'></td>
                                <td class='align-end' v-html='checkTypeDuration(data.results.averageDurationPerGame)'></td>
                                <td class='italic'>(duration/game)</td>
                            </tr>
                            <tr >
                                <td>Players</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.numberOfPlayers)'></td>
                                <td class='align-end' v-html='checkTypeDuration(data.results.averageDurationPerPlayer)'></td>
                                <td class='italic'>(avg. gambling)</td>
                            </tr>
                            <tr >
                                <td>Sessions</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.results.numberOfSessions)'></td>
                                <td class='align-end'  v-html='checkTypeDuration(data.results.averageDurationPerSession)'></td>
                                <td class='italic'>(avg. session)</td>
                            </tr>
                        </tbody> 
                    </template>
                </v-simple-table>
            </div>
        </div>
        </v-col>
        <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
            <div class="card card-teal" v-if='data' data-aos="fade">
            <div class="card_header">
                <span class="float-left">Today Players: Money Transactions</span>
                <span></span>
            </div>
            <div class="table_container gen_table small_pad">
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>In</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyTransactions.moneyIn)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyTransactions.averageMoneyIn)'></td>
                                <td class='italic'>(avg. transaction)</td>
                            </tr>
                            <tr>
                                <td>Out</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyTransactions.moneyOut)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyTransactions.averageMoneyOut)'></td>
                                <td class='italic'>(avg. transaction)</td>
                            </tr>
                            <tr>
                                <td>Balance</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyTransactions.balance)'></td>
                                <td class='align-end'></td>
                                <td class='italic'></td>
                            </tr>

                            <tr>
                                <td colspan=100% class='second_header'>Today Players: Money Status</td>
                            </tr>
                            <tr>
                                <td>Wallets</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyWallet.total)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyWallet.average)'></td>
                                <td class='italic'>(avg. size)</td>
                            </tr>
                            <tr>
                                <td>Credits</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.currentCredits.totalCurrentCredits)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.currentCredits.averageCurrentCredits)'></td>
                                <td class='italic'>(avg. size)</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyWallet.total+data.moneyBalance.currentCredits.totalCurrentCredits)'></td>
                                <td class='align-end' v-html='checkTypeCurrency(data.moneyBalance.moneyWallet.average+data.moneyBalance.currentCredits.averageCurrentCredits)'></td>
                                <td class='italic'>(avg. size)</td>
                            </tr>
                        </tbody> 
                    </template>
                </v-simple-table>
            </div>
        </div>
        </v-col>
        <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
            <div class="card card-teal" v-if='data' data-aos="fade">
                <div class="card_header">
                    <span class="float-left">Top 15 Breakdown</span>
                    <div class='sortMenu_container float-right'>
                        <v-menu content-class="sortMenu" offset-y left max-width='190' data-aos="fade">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="white" fab x-small dark v-bind="attrs" v-on="on" :elevation='0'>
                                    <span class="sort">{{currentSort}}</span>
                                    <v-icon class="menu_icon">mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in sortItems" :key="index" v-ripple @click="setSort(item)">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <!-- <div class='sortMenu_container float-right'>
                        <v-menu content-class="sortMenu" offset-y left max-width='190' data-aos="fade">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="white" fab x-small dark v-bind="attrs" v-on="on" :elevation='0'>
                                    <span class="sort">{{currentValue}}</span>
                                    <v-icon class="menu_icon">mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in sortValue" :key="index" v-ripple @click="setValueSort(item)">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div> -->
                </div>
                <crud-table class="gen_table" :data='currentData' :fields='currentField'></crud-table>
            </div>
        </v-col>
        <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
            <div class="card card-teal" v-if='data' data-aos="fade">
                <div class="card_header">
                    <span class="float-left">Extended Indicators</span>
                    <span></span>
                </div>
            
                <div class="table_container gen_table small_pad">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>Drop Speed</td>
                                    <td class='align-end'>??</td>
                                    
                                </tr>
                                <tr>
                                    <td>Drop / Machine</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.averageDropPerMachine)'></td>
                                    
                                </tr>
                                <tr>
                                    <td>result / Machine</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.averageResultPerMachine)'>??</td>
                                    
                                </tr>
                                <tr>
                                    <td colspan=100% class='second_header'>Maximums</td>
                                </tr>
                                    <tr>
                                    <td>Credit</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.maxCredits)'></td>
                                    
                                </tr>
                                <tr>
                                    <td>PayOut</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.maxCancelledCredits)'></td>
                                    
                                </tr>
                                <tr>
                                    <td>Session Duration</td>
                                    <td class='align-end' v-html='checkTypeDuration(data.results.maxDuration)'></td>
                                    
                                </tr>
                                <tr>
                                    <td colspan=100% class='second_header'>Averages</td>
                                </tr>
                                <tr>
                                    <td>Max Credit to PayOut time</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.maxCreditsToPayoutDuration)'></td>
                                    
                                </tr>
                                <tr>
                                    <td>Result / Player</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.averageResultPerPlayer)'></td>
                                    
                                </tr>
                                <tr>
                                    <td>Games / Player</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.averageGamesPerPlayer)'></td>
                                </tr>
                                <tr>
                                    <td>Games / Session</td>
                                    <td class='align-end' v-html='checkTypeCurrency(data.results.averageGamesPerSession)'></td>
                                </tr>
                            </tbody> 
                        </template>
                    </v-simple-table>
                </div>
            </div>
        </v-col>
        <v-col xs='12' sm='12' md='12' lg='4' xl='4'>
            <div class="card card-teal" v-if='data' data-aos="fade">
                <div class="card_header">
                    <span class="float-left">PayOut Tops</span>
                    <span></span>
                </div>
                <crud-table class="gen_table" v-if='data' :data='data.topPayouts' :fields='payOutFields'></crud-table>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import CrudTable from '@/components/tables/CrudTable.vue';

export default {
    props:['data'],
    components:{
        'crud-table':CrudTable 
    },
    data() {
        return {
            payOutFields:[
                {
                    label: 'Player',
                    getValue: item => item.playerGivenName+' '+item.playerFamilyName,
                    type: 'text',
                    key: 'player'
                },
                {
                    label: 'EGM',
                    getValue: item => item.egm + ' (' + item.manufacturer+')',
                    type: 'text',
                    key: 'egm'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.numberOfGames),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'games'
                },
                {
                    label: 'PayOut',
                    getValue: item => this.checkTypeCurrency(item.totalCancelledCredits),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'payout'
                },
                {
                    label: 'TotalBet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
            ],
            topManufacuterFields:[
                {
                    label: 'Name',
                    getValue: item => item.manufacturer,
                    type: 'text',
                    key: 'name'
                },
                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {
                    label: 'Result',
                    getValue: item => this.checkTypeCurrency(item.result),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'result'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.gamesPlayed),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'gamesPlayed'
                },
                {
                    label: 'Drop',
                    getValue: item => this.checkTypeCurrency(item.totalDrop),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalDrop'
                },
            ],
            topPayoutFields:[
                {
                    label: 'Name',
                    getValue: item => item.playerGivenName+' '+item.playerFamilyName,
                    type: 'text',
                    key: 'name'
                },
                {
                    label: 'Zone',
                    getValue: item => item.zone,
                    type: 'text',
                    key: 'zone'
                },
                {
                    label: 'EGM',
                    getValue: item => item.egm,
                    type: 'text',
                    key: 'egm'
                },
                {
                    label: 'Manufacturer',
                    getValue: item => item.manufacturer,
                    type: 'text',
                    key: 'anufacturer'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.numberOfGames),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfGames'
                },
                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
            ],
            currentSort: 'Manufacturer',
            currentData: null,
            currentField: null,
            currentValue: 'Percentage'
        }
    },
    computed:{
        sortItems: function(){
            return [
                {
                    name:'Manufacturer',
                    data: this.data.topManufacturers,
                    field: this.topManufacuterFields
                },
                {
                    name:'Payouts',
                    data: this.data.topPayouts,
                    field: this.topPayoutFields
                }
            ]
        },
        sortValue: function(){
            return [
                {
                    name: 'Value'
                },
                {
                    name: 'Percentage'
                }
            ]
        }
    },
    mounted(){
       this.setSort(this.sortItems[0]);
       this.setValueSort(this.sortValue[0]);
    },
    methods:{
        setSort(item) {
            this.currentSort = item.name;
            this.currentData = item.data;
            this.currentField = item.field;
        },
        setValueSort(item) {
            this.currentValue = item.name;
        },
    },
    watch: {
        data: function(){
            this.setSort(this.sortItems[0]);
        },
        deep: true
    }
}
</script>
