<template>
    <div class='tab brief'>
        <v-row style='margin-top:20px'>
            <v-col cols='4'>
                <v-row>
                    <v-col cols='12'>
                        <div class="card card-teal"  v-if='playerData' data-aos="fade">
                            <div class="card_header">
                                <span class="float-left">Basic Info</span>
                                <span></span>
                            </div>
                            <v-row>
                                <v-col cols='3'>
                                    <v-img src='/img/images_placeholder2x.png'></v-img>
                                </v-col>
                                <v-col cols='9'>
                                    <div class="table_container gen_table small_pad">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                            <tbody>
                                                <tr
                                                v-for="(item,index) in playerInfo"
                                                :key="index"
                                                >
                                                <td>{{ item.label }}</td>
                                                <td>{{ item.value }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols='12'>
                        <div class="card card-teal"  data-aos="fade">
                            <div class="card_header">
                                <span class="float-left">Money</span>
                                <span></span>
                            </div>
                            <div class="table_container gen_table small_pad">
                               <v-simple-table dense>
                                <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td>Money In</td>
                                        <td class='align-end'>{{data.moneyBalance.moneyIn | currency}}</td>
                                        <td>Wallet</td>
                                        <td></td>                                       
                                    </tr>
                                    <tr>      
                                        <td>Money OUT</td>                                   
                                        <td class='align-end'>{{data.moneyBalance.moneyOut | currency}}</td>
                                        <td>Current Credits</td>
                                        <td></td> 
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td class='align-end'>{{data.moneyBalance.moneyIn+data.moneyBalance.moneyOut | currency}}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr></tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols='6'>
                <div class="card card-teal"  data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Chart</span>
                        <span></span>
                    </div>
                    <div class="table_container gen_table small_pad">
                        
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols='5'>
                <div class="card card-teal" v-if='overallData' data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Overall Results</span>
                        <span></span>
                    </div>
                    <div class="table_container gen_table small_pad">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                                <tr
                                v-for="(item,index) in overallRes"
                                :key="index"
                                >
                                    <td >{{ item.label }}</td>
                                    <td class='align-end' v-if='item.label === "Total Duration"' >{{item.value | duration}}</td>
                                    <td class='align-end' v-if='item.label !== "Total Duration"' >{{item.value | currency}}</td>
                                    <td class='align-end'>{{item.avgValue | currency}}</td>
                                    <td class='italic'>{{ item.infoLabel }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-col>
            <v-col cols='5'>
                <div class="card card-teal"  data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Interval Analysis</span>
                        <span></span>
                    </div>
                    <div class="table_container gen_table small_pad">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                                <tr
                                v-for="(item,index) in intervalRes"
                                :key="index"
                                >
                                    

                                    <td >{{ item.label }}</td>
                                    <td class='align-end' v-if='item.label === "Total Duration"' v-html='checkTypeDuration(item.value)'></td>
                                    <td class='align-end' v-if='item.label !== "Total Duration"' v-html='checkTypeCurrency(item.value)'></td>
                                    <td class='align-end' v-html='checkTypeCurrency(item.avgValue)'></td>
                                    <td class='italic'>{{ item.infoLabel }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import CrudZone from '@/components/CrudZone.vue'
export default {
    components: {
        'crud-zone' : CrudZone
    },
    props:['data','casinoData','playerData'],
    data(){
        return {
            overallData:null,
            moneyFields : [
                {
                    label: 'Time Stamp',
                    getValue: item => Vue.moment(item.createdAt).format('MMM D YYYY - HH:mm:ss'),
                    type: 'text',
                    key: 'timeStamp'
                },
                {
                    label: 'Direction',
                    getValue: item => item.type,
                    type: 'text',
                    key: 'direction'
                },
                {
                    label: 'Value',
                    getValue: item => this.checkTypeCurrency(item.value),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'value'
                }
           ]
        }
    },
    computed: {
        playerInfo: function() {
            return [
                {
                    label: 'First Name',
                    value: this.playerData.givenName
                },
                {
                    label: 'Last Name',
                    value: this.playerData.familyName
                },
                {
                    label: 'DoB',
                    value: Vue.moment(this.playerData.dateOfBirth).format('MMM D YYYY')
                },
                {
                    label: 'Gender',
                    value: null
                },
                {
                    label: 'Nationality',
                    value: null
                }
            ]
        },
        intervalRes: function() {
            return [
                {
                    label: 'Total Bet',
                    value: this.data.results.totalBet,
                    avgValue : this.data.results.averageBetPerGame,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Result',
                    value: this.data.results.result,
                    avgValue : 0,
                    infoLabel: '(actual %)'
                },
                {
                    label: 'No of Games',
                    value: this.data.results.gamesPlayed,
                    avgValue : this.data.results.averageResultPerGame,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Drop',
                    value: this.data.results.totalDrop,
                    avgValue : 0,
                    infoLabel: null
                },
                {
                    label: 'Total Duration',
                    value: this.data.results.totalDuration,
                    avgValue : this.data.results.averageDurationPerGame,
                    infoLabel: '(duration/game)'
                },
                {
                    label: 'Total EGMs',
                    value: 0,
                    avgValue : 0,
                    infoLabel: '(avg. gambling)'
                },
                {
                    label: 'Total Sessions',
                    value: this.data.results.numberOfSessions,
                    avgValue : this.data.results.averageDurationPerSession,
                    infoLabel: '(avg. session)'
                }
            ]
        },
        overallRes: function() {
            return [
                {
                    label: 'Total Bet',
                    value: this.overallData.totalBet.value,
                    avgValue : this.overallData.averageBetPerGame.value,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Result',
                    value: this.overallData.result.value,
                    avgValue : 0,
                    infoLabel: '(actual %)'
                },
                {
                    label: 'No of Games',
                    value: this.overallData.gamesPlayed.value,
                    avgValue : this.overallData.averageResultPerGame.value,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Drop',
                    value: this.overallData.totalDrop.value,
                    avgValue : 0,
                    infoLabel: null
                },
                {
                    label: 'Total Duration',
                    value: this.overallData.totalDuration.value,
                    avgValue : this.overallData.averageDurationPerGame.value,
                    infoLabel: '(duration/game)'
                },
                {
                    label: 'Total EGMs',
                    value: 0,
                    avgValue : 0,
                    infoLabel: '(avg. gambling)'
                },
                {
                    label: 'Total Sessions',
                    value: this.overallData.numberOfSessions.value,
                    avgValue : this.overallData.averageDurationPerSession.value,
                    infoLabel: '(avg. session)'
                }
            ]
        }
    },
    mounted(){
        this.getOverallRes();
    },
    methods: {
        getOverallRes(){
            axios.get('operational/casinos/'+this.$route.params.casino_id+'/players/'+this.$route.params.player_id+'/cResults?startDate=2019-01-01T06:00&endDate='+this.calcDates(this.getRefinedTodayDate(),-1,'days')+'&cStartDate=2019-01-01T06:00&cEndDate='+this.calcDates(this.getRefinedTodayDate(),-1,'days'))
                .then(res=>{
                    this.overallData = res.data;
                });
        },
        getRefinedTodayDate(){                                                                              //casino offset in hours
            const newDate = new Date();
            const msDate = newDate.getTime();                                                                           //tranform date to miliseconds
            const todayDate = msDate - this.casinoData.settings.hourOffset*60*60*1000;                                  //subtract casino time offset to determine day in ms
            const todayDateIso = new Date(todayDate);                                                                   //create new Date object from offset date
            const timeZoneOffset = newDate.getTimezoneOffset();                                                         //get time-zone offset
            todayDateIso.setHours(this.casinoData.settings.hourOffset-timeZoneOffset/60,0,0);                                                  //set casino offset hour to determined day
            const refinedTodayDate = todayDateIso.toISOString().substring(0, todayDateIso.toISOString().length-8);      //remove seconds and ms from date
            return  refinedTodayDate;
        },
        calcDates(today,minus,period){
            let a = Vue.moment(today).subtract(minus,period).format();
            let b = a.substring(0, a.length-9);
            return b;
        }
    }
}
</script>