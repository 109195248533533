<template>
    <div class="tab sessions" >
        <v-row v-if='data'  data-aos="fade" style='margin-top:20px'>
            <v-col col="12">
                <div class="card card-teal" :class='{"page-loading":pageLoading}'>
                    <crud-zone-page
                        label='EGMs'
                        :fields="fields" 
						:data="data" 
                        :pageSizes='pageSizes'
                        @sessionData = 'getEgms'
                    />
                </div>
            </v-col>
        </v-row>                
    </div>
</template>
<script>
import CrudZonePage from '@/components/CrudZonePage.vue'

export default {
	 components:{
        'crud-zone-page':CrudZonePage
    },
    props:['filterDate'],
	data(){
		return {
			data:null,
			pageLoading: false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1
            },
			fields: [
				{
					label: 'Serial No',
					getValue: item => item.egMachineSerial,
					getLink: item => '/panel/operational/casino/'+this.$route.params.casino_id+'/egm/' + item.egMachineId, 
					type: 'text',
					key: 'serial',
					required: true,
				},
				{
					label: 'Manufacturer',
					getValue: item => item.egMachineManufacturer,
					key: 'manufacturerId',
					required: true,
				},
				{
					label: 'Total Bet',
					getValue: item => this.checkTypeCurrency(item.totalBet),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'totalBet',
					required: true,
				},
				{
					label: 'Total Win',
					getValue: item => this.checkTypeCurrency(item.totalWin),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'totalWin',
					required: true,
				},
				{
					label: 'Result',
					getValue: item => this.checkTypeCurrency(item.result),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'result',
					required: true
				},
				{
					label: 'Theoretical Result',
					getValue: item => this.checkTypeCurrency(item.theoreticalResult),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'theoryResult',
					required: true
				},
				{
					label: 'Actual RTP',
					getValue: item => this.checkTypeCurrency(item.actualRTP),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'actualRTP',
					required: true
				},
				{
					label: 'Theoretical RTP',
					getValue: item => this.checkTypeCurrency(item.theoreticalRTP),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'theoreticalRTP',
					required: true
				},
				{
					label: 'Games',
					getValue: item => this.checkTypeCurrency(item.gamesPlayed),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'gamesPlayed',
					required: true
				},
				{
					label: 'Total Drop',
					getValue: item => this.checkTypeCurrency(item.totalDrop),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'totalDrop',
					required: true
				},
				{
					label: 'Max Credits',
					getValue: item => this.checkTypeCurrency(item.maxCredits),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'maxCredits',
					required: true
				},
				{
					label: 'No of Sessions',
					getValue: item => this.checkTypeCurrency(item.numberOfSessions),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'numberOfSessions',
					required: true
				},
				{
					label: 'No of Players',
					getValue: item => this.checkTypeCurrency(item.numberOfPlayers),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'numberOfPlayers',
					required: true
				},
				{
					label: 'No of Opened Sessions',
					getValue: item => this.checkTypeCurrency(item.numberOfOpenedSessions),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'numberOfOpenedSessions',
					required: true
				},
				{
					label: 'No of Closed Sessions',
					getValue: item => this.checkTypeCurrency(item.numberOfClosedSessions),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'numberOfClosedSessions',
					required: true
				},
				{
					label: 'Duration',
					getValue: item => this.checkTypeDuration(item.duration),
					getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
					key: 'duration',
					required: true
				},
				
			],
		}
	},
	watch: {
        filterDate: function(oldDate, newDate){
			this.initialPage.index = 1;
			this.$root.$emit('setFirstPage');
            this.getEgms(this.initialPage);
        }
    },
    mounted(){
        this.getEgms(this.initialPage);
    },
    methods:{
        getEgms(item){
			this.pageLoading = true;
            this.initialPage.size = item.size;
            axios.get('operational/casinos/'+this.$route.params.casino_id+'/zones/'+this.$route.params.zone_id+'/cegms?'+this.filterDate+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.data = res.data;
					this.pageLoading = false;
                })
        }
    }
}
</script>