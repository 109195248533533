<template>
    <div class="page casino">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if="zoneData" data-aos="fade">
                            <div class="title">{{zoneData.name}}</div>
                            <div class="subtitle">{{selectedRange}}</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <date-picker v-if='casinoData' :casinoData='casinoData' @filterDateAPI='getData'/>
                        </div>
                        
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if="data" data-aos="fade">
                    <v-col cols="12">
                        <v-slide-group 
                        show-arrows
                        >
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card class="nav_ops_stats_color" :class='item.class'>
                                    <p class='label' v-if='!item.type'>{{item.label}}</p>
                                    <span class='label' v-if='item.type'>{{item.label[0]}}</span>
                                    <span class='label float-right' v-if='item.type'>{{item.label[1]}}</span>

                                    <div>
                                        <span class='status' v-if='!item.type'>{{item.status}}</span>

                                        <span class='status' v-if='item.type'>{{item.status[0]}}</span>
                                        <span class='status float-right' v-if='item.type'>{{item.status[1]}}</span>
                                    </div>
                                    <div>
                                        <span class='add_info' v-if='!item.type'>{{item.value}}</span>

                                        <span class='add_info' v-if='item.type'>{{item.value[0]}}</span>
                                        <span class='add_info float-right' v-if='item.type'>{{item.value[1]}}</span>
                                    </div>
                                    <div class='diag' v-if='item.type=="diag"'>
                                        <div class="track_container">
                                            <span class='track'>
                                                <span class='point' :style="{top:walletsPer+'%'}"></span>
                                            </span>   
                                        </div>
                                        <div class="diag_info_container">
                                            <p>Credits</p>
                                            <p>{{creditsPer}}%</p>
                                            <p>{{walletsPer}}%</p>
                                            <p>Wallets</p>
                                        </div>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                        
                    </v-col>
                </v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='12' lg='12' xl='12'>
                        <div class="navigation navigation-teal">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view v-if='data' :data='data' :filterDate='filterDate'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import ToggleButton from '@/components/navigation/ToggleButton.vue'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
    components:{
        'tab-nav':TabNav,
        'toggle-button':ToggleButton,
        'date-picker': DatePicker
    },
    data(){
        return {
            data: null, 
            zoneData:null,  
            casinoData:null, 
            //currentRange: 'Today',        
            tabNames: [
                {
                    label:'Info',
                    route:'info'   
                },
                {
                    label:'EGMs',
                    route:'egm'   
                },
                {
                    label:'List of Players',
                    route:'player'   
                },
                {
                    label:'Sessions',
                    route:'session'   
                },
                {
                    label:'Tops',
                    route:'top'
                },
                {
                    label:'Alarms',
                    route:'alarm'
                }
            ],
            //currentFilteredDate:null
        }
    },
    computed:{
        cardInfo: function(){
            return [
            {
                label:'TOTAL AVAILABLE',
                status: this.checkTypeCurrency(this.data.egm.totalAvailable),
                value: null,
                class: 'isLive',
                type: null
            },
            {
                label:'REMOTE PLAYING NOW',
                status: this.checkTypeCurrency(this.data.egm.remotePlay),
                value: null,
                class: 'isBlue',
                type: null
            },
            {
                label:['RESULT'],
                status: [this.checkTypeCurrency(this.data.results.result.value),'??'],
                value: ['?? Avg/EGM','?? Diff. to Th%'],
                class: 'isLive',
                type: 'col'
            },
            {
                label: 'TOTAL BET',
                status: this.checkTypeCurrency(this.data.results.totalBet.value),
                value: this.checkTypeCurrency(this.data.results.averageBetPerGame.value)+' Avg Bet',
                class: 'isBlue',
                type: null
            },
            {
                label: ['PLAYERS','GAMES'],
                status: [this.checkTypeCurrency(this.data.results.numberOfPlayers.value), this.checkTypeCurrency(this.data.results.gamesPlayed.value)],
                value:  ['?? Avg Sessions',this.checkTypeCurrency(this.data.results.averageGamesPerSession.value)+' Avg/Session'],
                class: 'isGray',
                type: 'col'
            },
            {
                label: ['MONEY BALANCE'],
                status: [this.checkTypeCurrency(this.data.moneyBalance.moneyWallet.total)],
                value:  [this.checkTypeCurrency(this.data.moneyBalance.moneyTransactions.averageMoneyIn.value)+' Avg IN', this.checkTypeCurrency(this.data.moneyBalance.moneyTransactions.averageMoneyOut.value)+' Avg OUT'],
                class: 'isOffline',
                type: 'col'
            },
            {
                label: ['CLIENT MONEY'],
                status: [this.checkTypeCurrency(this.data.moneyBalance.currentCredits.totalCurrentCredits)],
                value: [this.checkTypeCurrency(this.data.moneyBalance.currentCredits.averageCurrentCredits)+' Avg/Player'],
                class: 'isGray',
                type: 'diag'
            },
        ]
        },
        creditsPer :function(){
            return (this.data.moneyBalance.currentCredits.totalCurrentCredits*100/(this.data.moneyBalance.currentCredits.totalCurrentCredits+this.data.moneyBalance.moneyWallet.total)).toFixed(0)
        },
        walletsPer: function(){
             return (this.data.moneyBalance.moneyWallet.total*100/(this.data.moneyBalance.currentCredits.totalCurrentCredits+this.data.moneyBalance.moneyWallet.total)).toFixed(0)
        },
        ...mapGetters(['selectedRange']),
        ...mapGetters(['filterDate']),
    },
    async mounted(){
        await this.getCasino();
        this.getCasinoZones();
    },
    methods: {
        getCasinoZones(){
            axios.get('casinoZones/'+ this.$route.params.zone_id)
                .then(res=>{
                    this.zoneData = res.data;         
                });
        },

        getCasino(){
            axios.get('system/casinos/'+ this.$route.params.casino_id)
                .then(res=>{
                    this.casinoData = res.data;         
                });
        },

        getData(value){
           
            var self = this;
            //this.currentFilteredDate = value;
            
            function egms(){
                return axios.get('operational/casinos/'+self.$route.params.casino_id+'/zones/'+self.$route.params.zone_id+'/egms/statuses');
            };

            function results(value){
                return axios.get('operational/casinos/'+self.$route.params.casino_id+'/zones/'+self.$route.params.zone_id+'/cResults?'+self.filterDate);
            };

            function topPayouts(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/zones/'+self.$route.params.zone_id+'/topPayouts?'+self.filterDate);
            };

            function topManufacturers(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/zones/'+self.$route.params.zone_id+'/topManufacturers?'+self.filterDate.substring(0,51));
            };
            
            function moneyBalance(value){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/zones/'+self.$route.params.zone_id+'/cMoneyBalance?'+self.filterDate);
            };

            Promise.all([egms(), results(value), topPayouts(value), topManufacturers(value),  moneyBalance(value)])
                    .then(res=>{
                        this.data = {};
                        this.data.egm = res[0].data;
                        this.data.results = res[1].data;
                        this.data.topPayouts = res[2].data;
                        this.data.topManufacturers = res[3].data;
                        this.data.moneyBalance = res[4].data;
                        // this.data.moneyBalance = res[4].data;
                    });
        },

        // setCurrentRange(value){
        //     this.currentRange = value.label;
        // }
    }
}
</script>
