<template>
    <div class="tab players" >
        <v-row v-if='data'  data-aos="fade" style='margin-top:20px'>
            <v-col col="12">
                <div class="card card-teal" :class='{"page-loading":pageLoading}'>
                    <crud-zone-page
                        :key='1'
                        label='Players'
                        :fields="fields" 
						:data="data" 
                        :pageSizes='pageSizes'
                        @sessionData = 'getPlayers'
                    />
                </div>
            </v-col>
        </v-row>                
    </div>
</template>
<script>
import CrudZonePage from '@/components/CrudZonePage.vue'

export default {
    components:{
        'crud-zone-page':CrudZonePage
    },
    props:['filterDate'],
    data(){
        return {
            data:null,
            pageLoading:false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1
            },
            fields:[
                {
                    label: 'Player Id',
                    getValue: item => item.playerId,
                    getLink: item => '/panel/operational/casino/'+this.$route.params.casino_id+'/player/' + item.playerId, 
                    type: 'text',
                    key: 'playerId'
                },
                {
                    label: 'Player Name',
                    getValue: item => item.playerGivenName+' '+ item.playerFamilyName,
                    getLink: item => '/panel/operational/casino/'+this.$route.params.casino_id+'/player/' + item.playerId, 
                    type: 'text',
                    key: 'playerName'
                },
                {
                    label: 'Duration',
                    getValue: item => this.formatDuration(item.duration),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'duration'
                },
                {
                    label: 'Total Bet',
                    getValue: item => item.totalBet,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {
                    label: 'Total Win',
                    getValue: item => item.totalWin,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalWin'
                },
                {
                    label: 'Actual RTP',
                    getValue: item => item.actualRTP,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'actualRTP'
                },
                {
                    label: 'Games Played',
                    getValue: item => item.gamesPlayed,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'games'
                },
                {
                    label: 'Total Drop',
                    getValue: item => item.totalDrop,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'drop'
                },
                {
                    label: 'Max Credit',
                    getValue: item => item.maxCredits,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'maxCredits'
                },
                {
                    label: 'No of Sessions',
                    getValue: item => item.numberOfSessions,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'sessions'
                },
            ]
        }
    },
    watch: {
        filterDate: function(oldDate, newDate){
            this.initialPage.index = 1;
            this.$root.$emit('setFirstPage');
            this.getPlayers(this.initialPage);
        }
    },
    mounted(){
        this.getPlayers(this.initialPage);
    },
    methods:{
        getPlayers(item){
            this.pageLoading = true;
            this.initialPage.size = item.size;
            axios.get('operational/casinos/'+this.$route.params.casino_id+'/players?'+this.filterDate+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.data = res.data;
                    this.pageLoading = false;
                })
        }
    }
}
</script>