<template>
    <div class="tab tops" style="margin-top: 20px;">
        <v-row>
            <v-col col="12">
                <div class="card card-teal" v-if='data' data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Alarms</span>
                        <span></span>
                    </div>
                    <crud-table class="gen_table"  :data='data' :fields='fields'></crud-table>
                </div>
            </v-col>
        </v-row>                     
    </div>
</template>
<script>
import CrudTable from '@/components/tables/CrudTable.vue'

export default {
    components:{
        'crud-table':CrudTable
    },
    props:['data'],
    data(){
        return {
            fields:[
                {
                    label: 'TimeStamp',
                    getValue: item => '??',
                    type: 'text',
                    key: 'timeStamp'
                },
                {
                    label: 'Type',
                    getValue: item => '??',
                    type: 'text',
                    key: 'type'
                },
                {
                    label: 'Zone',
                    getValue: item => '??',
                    type: 'text',
                    key: 'zone'
                },
                {
                    label: 'EGM',
                    getValue: item => '??',
                    type: 'text',
                    key: 'egm'
                },
                {
                    label: 'Manufacturer',
                    getValue: item => '??',
                    type: 'text',
                    key: 'manufacturer'
                },
                {
                    label: 'Player',
                    getValue: item => '??',
                    type: 'text',
                    key: 'player'
                },
                {
                    label: 'Extra Info',
                    getValue: item => '??',
                    type: 'text',
                    key: 'extraInfo'
                }
            ]
        }
    }
}
</script>