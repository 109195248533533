<template>
    <div class='tab brief'>
        <v-row style='margin-top:20px'>
            <v-col cols='4'>
                <v-row>
                    <v-col cols='12'>
                        <div class="card card-teal" data-aos="fade">
                            <div class="card_header">
                                <span class="float-left">EGM Info</span>
                                <span></span>
                            </div>
                            <v-row>
                                <v-col cols='3'>
                                    <v-img :src='getImage(egmData.model.mediaFiles[0].fileName)'></v-img>
                                </v-col>
                                <v-col cols="9">
                                    <div class="table_container gen_table small_pad">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                            <tbody>
                                                <tr
                                                v-for="(item,index) in egmInfo"
                                                :key="index"
                                                >
                                                <td>{{ item.label }}</td>
                                                <td>{{ item.value }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols='12' v-if='moneyTransactions' data-aos='fade'>
                        <div class="card card-teal" :class='{"page-loading":pageLoading}'>
                            <crud-zone-page
                                label='Credits In/Out'
                                :fields="creditFields" 
                                :data="moneyTransactions" 
                                :pageSizes='pageSizes'
                                @sessionData = 'getMoneyTransactions'
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols='6'>
                <div class="card card-teal"  data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Chart</span>
                        <span></span>
                    </div>
                    <div class="table_container gen_table small_pad">
                        
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols='5'>
                <div class="card card-teal" v-if='overallData' data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Overall Results</span>
                        <span></span>
                    </div>
                    <div class="table_container gen_table small_pad">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                                <tr
                                v-for="(item,index) in overallRes"
                                :key="index"
                                >
                                    <td >{{ item.label }}</td>
                                    <td class='align-end' v-if='item.label === "Total Duration"' >{{item.value | duration}}</td>
                                    <td class='align-end' v-if='item.label !== "Total Duration"' >{{item.value | currency}}</td>
                                    <td class='align-end'>{{item.avgValue | currency}}</td>
                                    <td class='italic'>{{ item.infoLabel }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-col>
            <v-col cols='5'>
                <div class="card card-teal"  data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Interval Analysis</span>
                        <span></span>
                    </div>
                    <div class="table_container gen_table small_pad">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <tbody>
                                <tr
                                v-for="(item,index) in intervalRes"
                                :key="index"
                                >
                                    <td >{{ item.label }}</td>
                                    <td class='align-end' v-if='item.label === "Total Duration"' v-html='checkTypeDuration(item.value)'></td>
                                    <td class='align-end' v-if='item.label !== "Total Duration"' v-html='checkTypeCurrency(item.value)'></td>
                                    <td class='align-end' v-html='checkTypeCurrency(item.avgValue)'></td>
                                    <td class='italic'>{{ item.infoLabel }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-col>
            
        </v-row>
    </div>
</template>
<script>
import CrudZonePage from '@/components/CrudZonePage.vue'
export default {
    components: {
        'crud-zone-page' : CrudZonePage
    },
    props:['egmData', 'data', 'casinoData','filterDate'],
    data(){
        return {
            overallData:null,
            moneyTransactions:null,
            pageLoading: false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1
            },
            creditFields : [
                {
                    label: 'Timestamp',
                    getValue: item => Vue.moment(item.createdAt).format('MMM D YYYY - HH:mm:ss'),
                    type: 'text',
                    key: 'timeStamp'
                },
                {
                    label: 'Type',
                    getValue: item => item.type,
                    type: 'text',
                    key: 'type'
                },
                {
                    label: 'Value',
                    getValue: item => this.checkTypeCurrency(item.value),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'value'
                }
           ]
        }
    },
    watch: {
        filterDate: function(oldDate, newDate){
            this.initialPage.index = 1;
            this.$root.$emit('setFirstPage');
            this.getMoneyTransactions(this.initialPage);
        }
    },
    computed: {
        egmInfo: function() {
            return [
                {
                    label: 'Machine No:',
                    value: this.egmData.number
                },
                {
                    label: 'Cabinet:',
                    value: this.egmData.model.name
                },
                {
                    label: 'Machine RTP:',
                    value: this.egmData.rtp
                },
                {
                    label: 'Game Mix:',
                    value: this.egmData.gameMix.name
                },
                {
                    label: 'Denomination:',
                    value: this.egmData.denomination
                },
                {
                    label: 'EEPROM Version:',
                    value: this.egmData.eepromVersion
                },
                {
                    label: 'Game Mix Volatility:',
                    value: this.egmData.gameMix.volatility
                },
                {
                    label: 'Checksum:',
                    value: this.egmData.checksum
                }
            ]
        },
        intervalRes: function() {
            return [
                {
                    label: 'Total Bet',
                    value: this.data.results.totalBet,
                    avgValue : this.data.results.averageBetPerGame,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Result',
                    value: this.data.results.result,
                    avgValue : 0,
                    infoLabel: '(actual %)'
                },
                {
                    label: 'No of Games',
                    value: this.data.results.gamesPlayed,
                    avgValue : this.data.results.averageResultPerGame,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Drop',
                    value: this.data.results.totalDrop,
                    avgValue : 0,
                    infoLabel: null
                },
                {
                    label: 'Total Duration',
                    value: this.data.results.totalDuration,
                    avgValue : this.data.results.averageDurationPerGame,
                    infoLabel: '(duration/game)'
                },
                {
                    label: 'Total EGMs',
                    value: 0,
                    avgValue : 0,
                    infoLabel: '(avg. gambling)'
                },
                {
                    label: 'Total Sessions',
                    value: this.data.results.numberOfSessions,
                    avgValue : this.data.results.averageDurationPerSession,
                    infoLabel: '(avg. session)'
                }
            ]
        },
        overallRes: function() {
            return [
                {
                    label: 'Total Bet',
                    value: this.overallData.totalBet.value,
                    avgValue : this.overallData.averageBetPerGame.value,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Result',
                    value: this.overallData.result.value,
                    avgValue : 0,
                    infoLabel: '(actual %)'
                },
                {
                    label: 'No of Games',
                    value: this.overallData.gamesPlayed.value,
                    avgValue : this.overallData.averageResultPerGame.value,
                    infoLabel: '(avg. bet)'
                },
                {
                    label: 'Drop',
                    value: this.overallData.totalDrop.value,
                    avgValue : 0,
                    infoLabel: null
                },
                {
                    label: 'Total Duration',
                    value: this.overallData.totalDuration.value,
                    avgValue : this.overallData.averageDurationPerGame.value,
                    infoLabel: '(duration/game)'
                },
                {
                    label: 'Total EGMs',
                    value: 0,
                    avgValue : 0,
                    infoLabel: '(avg. gambling)'
                },
                {
                    label: 'Total Sessions',
                    value: this.overallData.numberOfSessions.value,
                    avgValue : this.overallData.averageDurationPerSession.value,
                    infoLabel: '(avg. session)'
                }
            ]
        }
    },
    mounted(){
        this.getOverallRes();
        this.getMoneyTransactions(this.initialPage);
    },
    methods: {
        getOverallRes(){
            axios.get('operational/casinos/'+this.$route.params.casino_id+'/egms/'+this.$route.params.egm_id+'/cResults?startDate=2019-01-01T06:00&endDate='+this.calcDates(this.getRefinedTodayDate(),-1,'days')+'&cStartDate=2019-01-01T06:00&cEndDate='+this.calcDates(this.getRefinedTodayDate(),-1,'days'))
                .then(res=>{
                    this.overallData = res.data;
                });
        },
        getMoneyTransactions(item){
                this.pageLoading = true;
                this.initialPage.size = item.size;
                axios.get('operational/casinos/'+this.$route.params.casino_id+'/egms/'+this.$route.params.egm_id+'/moneyTransactions?'+this.filterDate.substring(0,26)+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=> {
                    this.moneyTransactions = res.data;
                    this.pageLoading = false;
                })
        },
        getRefinedTodayDate(){                                                                                          
            const newDate = new Date();
            const msDate = newDate.getTime();                                                                           //tranform date to miliseconds
            const todayDate = msDate - this.casinoData.settings.hourOffset*60*60*1000;                                  //subtract casino time offset to determine day in ms
            const todayDateIso = new Date(todayDate);                                                                   //create new Date object from offset date
            const timeZoneOffset = newDate.getTimezoneOffset();                                                         //get time-zone offset
            todayDateIso.setHours(this.casinoData.settings.hourOffset-timeZoneOffset/60,0,0);                           //set casino offset hour to determined day
            const refinedTodayDate = todayDateIso.toISOString().substring(0, todayDateIso.toISOString().length-8);      //remove seconds and ms from date
            return  refinedTodayDate;
        },
        calcDates(today,minus,period){
            let a = Vue.moment(today).subtract(minus,period).format();
            let b = a.substring(0, a.length-9);
            return b;
        }
    }
}
</script>