<template>
    <div class="tab logins" >
        <v-row v-if='data'  data-aos="fade" style='margin-top:20px'>
            <v-col col="12">
                <div class="card card-teal" >
                    <crud-zone
                        label='Logins'
                        :fields="fields" 
						:data="data" 
                    />
                </div>
            </v-col>
        </v-row>                
    </div>
</template>
<script>
import CrudZone from '@/components/CrudZone.vue'

export default {
    components:{
        'crud-zone':CrudZone
    },
    props:['filterDate'],
    data(){
        return {
            data:null,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1
            },
            fields:[
                {
                    label: 'Id',
                    getValue: item => item.id,
                    type: 'text',
                    key: 'id'
                },
                {
                    label: 'EGM',
                    getValue: item => item.egMachineSerial,
                    type: 'text',
                    key: 'egm'
                },
                {
                    label: 'Manufacturer',
                    getValue: item => item.egMachineManufacturer,
                    type: 'text',
                    key: 'manufacturer'
                },
                {
                    label: 'Casino Zone',
                    getValue: item => item.casinoZoneName,
                    type: 'text',
                    key: 'manufacturer'
                },
                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {
                    label: 'Total Win',
                    getValue: item => this.checkTypeCurrency(item.totalWin),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalWin'
                },
                {
                    label: 'Games Played',
                    getValue: item => this.checkTypeCurrency(item.gamesPlayed),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'games'
                },
                {
                    label: 'Total Drop',
                    getValue: item => this.checkTypeCurrency(item.totalDrop),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'drop'
                },
                {
                    label: 'Duration',
                    getValue: item =>this.formatDuration(item.duration),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'duration'
                },
                {
                    label: 'Created date',
                    getValue: item =>Vue.moment(item.createdAt).format('MMM D YYYY - HH:mm:ss'),
                    type: 'text',
                    key: 'createdAt'
                },
            ]
        }
    },
    watch: {
        filterDate: function(oldDate, newDate){
            this.getLoginSessions();
        }
    },
    mounted(){
        this.getLoginSessions();
    },
    methods:{
        getLoginSessions(){
            axios.get('operational/casinos/'+this.$route.params.casino_id+'/players/'+this.$route.params.player_id+'/sessions?'+this.filterDate.substring(0,26))
                .then(res=>{
                    this.data = res.data
                })
        }
    }
}
</script>