<template>
    <div class="tab sessions" >
        <v-row v-if='sessionsData' data-aos="fade" style='margin-top:20px'>
            <v-col col="12">
                <div class="card card-teal" :class='{"page-loading":pageLoading}'>
                    <crud-zone-page
                        :key='1'
                        label='Sessions'
                        :fields="fields" 
						:data="sessionsData" 
                        :pageSizes='pageSizes'
                        :tableView='tableView'
                        @sessionData = 'getCasinoSessions'
                    />
                </div>
            </v-col>
        </v-row>               
    </div>
</template>
<script>
import CrudZonePage from '@/components/CrudZonePage.vue'


export default {
    components:{
        'crud-zone-page':CrudZonePage
    },
    props:['data','filterDate'],
    data(){
        return {
            sessionsData:null,
            pageLoading: false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1,
                session: 'Live'
            },
            tableView:['Live', 'Closed'],
            fields:[
                {
                    label: 'Number',
                    getValue: item => item.egMachineNumber + ' ('+ item.egMachineManufacturer +')',
                    type: 'text',
                    key: 'number'
                },
                {
                    label: 'Player Name',
                    getValue: item => item.playerGivenName+' '+ item.playerFamilyName,
                    type: 'text',
                    key: 'playerName'
                },
                {
                    label: 'Session Id',
                    getValue: item => item.pgSessionId,
                    type: 'text',
                    key: 'id'
                },
                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {
                    label: 'Result',
                    getValue: item => this.checkTypeCurrency(item.result),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'result'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.gamesPlayed),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'games'
                },
                {
                    label: 'Duration',
                    getValue: item => this.formatDuration(item.duration),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'duration'
                },
                {
                    label: 'Max Credit',
                    getValue: item => this.checkTypeCurrency(item.maxCredits),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'maxcreditss'
                },
            ]
        }
    },
    watch: {
        filterDate: function(oldDate, newDate){
            this.initialPage.index = 1;
            this.$root.$emit('setFirstPage');
            this.getCasinoSessions(this.initialPage);
        }
    },
    mounted(){
        this.getCasinoSessions(this.initialPage);
    },
    methods:{
        getCasinoSessions(item){
            this.pageLoading = true;
            this.initialPage.size = item.size;
            this.initialPage.session = item.session;
            if(item.session === 'Live'){
                axios.get('operational/casinos/'+this.$route.params.casino_id+'/zones/'+this.$route.params.zone_id+'/sessions/opened?'+this.filterDate.substring(0,26)+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.sessionsData = res.data;
                    this.pageLoading = false;
                })
            } else {
                 axios.get('operational/casinos/'+this.$route.params.casino_id+'/zones/'+this.$route.params.zone_id+'/sessions/closed/all?'+this.filterDate.substring(0,26)+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.sessionsData = res.data;
                    this.pageLoading = false;
                })
            }
        }
    }
}
</script>