<template>
    <div class="tab moneyTransactions" >
        <v-row v-if='data'  data-aos="fade" style='margin-top:20px'>
            <v-col col="12">
                <div class="card card-teal" :class='{"page-loading":pageLoading}'>
                    <crud-zone-page
                        :key='1'
                        label='Money Transactions'
                        :fields="fields" 
						:data="data" 
                        :pageSizes='pageSizes'
                        @sessionData = 'getMoneyTransations'
                    />
                </div>
            </v-col>
        </v-row>                
    </div>
</template>
<script>
import CrudZonePage from '@/components/CrudZonePage.vue'

export default {
    components:{
        'crud-zone-page':CrudZonePage
    },
    props:['filterDate'],
    data(){
        return {
            data:null,
            pageLoading: false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1
            },
            fields:[
                {
                    label: 'Type',
                    getValue: item => item.type,
                    type: 'text',
                    key: 'type'
                },
                {
                    label: 'Value',
                    getValue: item => item.value,
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'value'
                },
                {
                    label: 'Timestamp',
                    getValue: item => Vue.moment(item.createdAt).format('MMM D YYYY - HH:mm:ss'),
                    type: 'text',
                    key: 'duration'
                },
                {
                    label: 'Operation Id',
                    getValue: item => item.operationId,
                    type: 'text',
                    key: 'operationId'
                }
            ]
        }
    },
    watch: {
        filterDate: function(oldDate, newDate){
            this.initialPage.index = 1;
            this.$root.$emit('setFirstPage');
            this.getMoneyTransations(this.initialPage);
        }
    },
    mounted(){
        this.getMoneyTransations(this.initialPage);
    },
    methods:{
        getMoneyTransations(item){
            this.pageLoading = true;
            this.initialPage.size = item.size;
            axios.get('operational/casinos/'+this.$route.params.casino_id+'/players/'+this.$route.params.player_id+'/moneyTransactions?'+this.filterDate.substring(0,26)+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.data = res.data;
                    this.pageLoading = false;
                });
        }
    }
}
</script>