<template>
    <div class="tab tops" style="margin-top: 20px;">
        <v-row>
            <v-col col="12">
                <div class="card card-teal" v-if='data' data-aos="fade">
                    <div class="card_header">
                        <span class="float-left">Tops by</span>
                        <div class='sortMenu_container'>
                            <v-menu content-class="sortMenu" offset-y left max-width='190' data-aos="fade">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="white" fab x-small dark v-bind="attrs" v-on="on" :elevation='0'>
                                        <span class="sort">{{currentSort}}</span>
                                        <v-icon class="menu_icon">mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, index) in sortItems" :key="index" v-ripple @click="setSort(item)">
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <crud-table class="gen_table"  :data='currentData' :fields='currentField'></crud-table>
                </div>
            </v-col>
        </v-row>                     
    </div>
</template>
<script>
import CrudTable from '@/components/tables/CrudTable.vue'

export default {
    components:{
        'crud-table':CrudTable
    },
    props:['data'],
    data(){
        return {
            manufacturerFields:[
                {
                    label: 'Manufacturer',
                    getValue: item => item.manufacturer,
                    type: 'text',
                    key: 'manufacturer'
                },
                {
                    label: 'Total Drop',
                    getValue: item => this.checkTypeCurrency(item.totalDrop),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'drop'
                },
                {
                    label: 'Result',
                    getValue: item => this.checkTypeCurrency(item.result),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'result'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.gamesPlayed),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'gamesPlayed'
                },
                {
                    label: 'Min Bet',
                    getValue: item => this.checkTypeCurrency(item.minBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'minBet'
                },
                {
                    label: 'Max Bet',
                    getValue: item => this.checkTypeCurrency(item.maxBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'maxBet'
                },
                {
                    label: 'Sessions',
                    getValue: item => this.checkTypeCurrency(item.numberOfSessions),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfSessions'
                },
                {
                    label: 'Opened Sessions',
                    getValue: item => this.checkTypeCurrency(item.numberOfOpenedSessions),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfOpenedSessions'
                },
                {
                    label: 'Closed Sessions',
                    getValue: item => this.checkTypeCurrency(item.numberOfClosedSessions),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfClosedSessions'
                },
                {
                    label: 'Max Credits',
                    getValue: item => this.checkTypeCurrency(item.maxCredits),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'maxCredits'
                },
                {
                    label: 'Total PayOut',
                    getValue: item => this.checkTypeCurrency(item.totalCancelledCredits),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalCancelledCredits'
                },
            ],
            payoutFields:[
                {
                    label: 'Name',
                    getValue: item => item.playerGivenName+item.playerFamilyName,
                    type: 'text',
                    key: 'name'
                },
                {
                    label: 'Manufacturer',
                    getValue: item => item.manufacturer,
                    type: 'text',
                    key: 'manufacturer'
                },
                {
                    label: 'EGM',
                    getValue: item => item.egm,
                    type: 'text',
                    key: 'egm'
                },
                {
                    label: 'Zone',
                    getValue: item => item.zone,
                    type: 'text',
                    key: 'zone'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.numberOfGames),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfGames'
                },
                {
                    label: 'PayOut',
                    getValue: item => this.checkTypeCurrency(item.totalCancelledCredits),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalCancelledCredits'
                },
                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {
                    label: '% Total Bet',
                    getValue: item => this.checkTypeCurrency(item.percentFromTotalBet),
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'percentFromTotalBet'
                }
            ],
            currentSort: 'Manufacturer',
            currentData: null,
            currentField: null
        }
    },
    computed:{
        sortItems: function(){
            return [
                {
                    name:'Manufacturer',
                    data: this.data.topManufacturers,
                    field: this.manufacturerFields
                },
                {
                    name:'Payouts',
                    data: this.data.topPayouts,
                    field: this.payoutFields
                }
            ]
        }
    },
    mounted(){
       this.setSort(this.sortItems[0]);
    },
    watch: {
        data: function(to, from){
            const currIndex = (el) => el.name == this.currentSort;
            this.setSort(this.sortItems[this.sortItems.findIndex(currIndex)]);
        }
    },
    methods:{
        setSort(item) {
            this.currentSort = item.name;
            this.currentData = item.data;
            this.currentField = item.field;
        },
    }
}
</script>