<template>
    <div class="tab sessions" >
        <v-row v-if='sessionsData'  data-aos="fade" style='margin-top:20px'>
            <v-col col="12">
                <div class="card card-teal" :class='{"page-loading":pageLoading}'>
                    <crud-zone-page
                        :key='1'
                        label='Sessions'
                        :fields="fields" 
						:data="sessionsData" 
                        :pageSizes='pageSizes'
                        :tableView='tableView'
                        @sessionData = 'getCasinoSessions'
                    />
                </div>
            </v-col>
        </v-row>              
    </div>
</template>
<script>
import CrudZonePage from '@/components/CrudZonePage.vue'

export default {
    components:{
        'crud-zone-page':CrudZonePage
    },
    props:['data','filterDate'],
    data(){
        return {
            sessionsData:null,
            pageLoading: false,
            pageSizes:[10,20,50],
            initialPage: {
                size:10,
                index:1,
                session: 'Live'
            },
            tableView:['Live', 'Closed'],
            fields:[
                {
                    label: 'Player Id',
                    getValue: item => item.playerId,
                    type: 'text',
                    key: 'playerId'
                },
                {
                    label: 'Player Name',
                    getValue: item => item.playerGivenName+' '+ item.playerFamilyName,
                    type: 'text',
                    key: 'playerName'
                },
                {
                    label: 'Session Id',
                    getValue: item => item.pgSessionId,
                    type: 'text',
                    key: 'id'
                },
                {
                    label: 'Denomination',
                    getValue: item => item.denomination,
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'denomination'
                },
                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {   
                    label: 'Total Win',
                    getValue: item => this.checkTypeCurrency(item.totalWin),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalWin'
                },
                {
                    label: 'Total Drop',
                    getValue: item => this.checkTypeCurrency(item.totalDrop),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalDrop'
                },
                {
                    label: 'Actual RTP',
                    getValue: item => item.actualRTP,
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'actualRTP'
                },
                {
                    label: 'Theoretical RTP',
                    getValue: item => item.theoreticalRTP,
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'theoreticalRTP'
                },
                {
                    label: 'Result',
                    getValue: item => this.checkTypeCurrency(item.result),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'result'
                },
                {
                    label: 'Theoretical Result',
                    getValue: item => item.theoreticalResult,
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'theoreticalResult'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.gamesPlayed),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'games'
                },
                
                {
                    label: 'Duration',
                    getValue: item => this.formatDuration(item.duration),
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'duration'
                },
                {
                    label: 'Max Credit',
                    getValue: item => item.maxCredits,
                  getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'maxcreditss'
                },
            ]
        }
    },
    watch: {
        filterDate: function(oldDate, newDate){
            this.initialPage.index = 1;
            this.$root.$emit('setFirstPage');
            this.getCasinoSessions(this.initialPage);
        }
    },
    mounted(){
        this.getCasinoSessions(this.initialPage);
    },
    methods:{
        getCasinoSessions(item){
            this.pageLoading = true;
            this.initialPage.size = item.size;
            this.initialPage.session = item.session;
            if(item.session === 'Live'){
                axios.get('operational/casinos/'+this.$route.params.casino_id+'/egms/'+this.$route.params.egm_id+'/sessions/opened?'+this.filterDate.substring(0,26)+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.sessionsData = res.data;
                    this.pageLoading = false;
                })
            } else {
                 axios.get('operational/casinos/'+this.$route.params.casino_id+'/egms/'+this.$route.params.egm_id+'/sessions/closed/all?'+this.filterDate.substring(0,26)+'&pageSize='+item.size+'&pageIndex='+(item.index-1))
                .then(res=>{
                    this.sessionsData = res.data;
                    this.pageLoading = false;
                })
            }
        }
    }
}
</script>