<template>
    <div class="page casino">
        <div class="header">
			<v-container>
                <v-row>
                    <v-col cols='6'>
                        <div class="entity" v-if="egmData" data-aos="fade">
                            <div class="title">EGM {{egmData.serial}}</div>
                            <div class="subtitle">{{egmData.manufacturer.name}}</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="header_button_container">
                            <date-picker v-if='casinoData' :casinoData='casinoData' @filterDateAPI='getData'/>
                        </div>
                        
                    </v-col>
                </v-row>
                <v-row class="stats_container" v-if="data" data-aos="fade">
                    <v-col cols="12">
                        <v-slide-group 
                        show-arrows
                        >
                            <v-slide-item v-for='(item,index) in cardInfo' :key='index'>
                                <v-card class="nav_ops_stats_color" :class='item.class'>
                                    <p class='label' v-if='!item.type'>{{item.label}}</p>
                                    <span class='label' v-if='item.type'>{{item.label[0]}}</span>
                                    <span class='label float-right' v-if='item.type'>{{item.label[1]}}</span>

                                    <div>
                                        <span class='status' v-if='!item.type'>{{item.status}}</span>

                                        <span class='status' v-if='item.type'>{{item.status[0]}}</span>
                                        <span class='status float-right' v-if='item.type'>{{item.status[1]}}</span>
                                    </div>
                                    <div>
                                        <span class='add_info' v-if='!item.type'>{{item.value}}</span>

                                        <span class='add_info' v-if='item.type'>{{item.value[0]}}</span>
                                        <span class='add_info float-right' v-if='item.type'>{{item.value[1]}}</span>
                                    </div>
                                    <div class='diag' v-if='item.type=="diag"'>
                                        <div class="track_container">
                                            <span class='track'>
                                                <span class='point' :style="{top:walletsPer+'%'}"></span>
                                            </span>   
                                        </div>
                                        <div class="diag_info_container">
                                            <p>Credits</p>
                                            <p>{{creditsPer}}%</p>
                                            <p>{{walletsPer}}%</p>
                                            <p>Wallets</p>
                                        </div>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                        
                    </v-col>
                </v-row>
			</v-container>
		</div>
        <div>
            <v-container>
                <v-row>
                    <v-col xs='12' sm='12' md='12' lg='12' xl='12'>
                        <div class="navigation navigation-teal">
                            <tab-nav :tabNames="tabNames"></tab-nav>
                        </div>
                        <div class="content">
                            <router-view v-if='data && egmData && casinoData' :casinoData='casinoData' :egmData='egmData' :data='data' :filterDate='filterDate'></router-view>     
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import TabNav from '@/components/navigation/TabNav.vue'
import ToggleButton from '@/components/navigation/ToggleButton.vue'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
    components:{
        'tab-nav':TabNav,
        'toggle-button':ToggleButton,
        'date-picker': DatePicker
    },
    data(){
        return {
            data: null, 
            egmData:null,  
            casinoData:null, 
            tabNames: [
                {
                    label:'Brief',
                    route:'brief'   
                },
                {
                    label:'List of Players',
                    route:'player'   
                },
                {
                    label:'List of Games',
                    route:'game'   
                },
                {
                    label:'List of Sessions',
                    route:'session'
                }
            ],
        }
    },
    computed:{
        cardInfo: function(){
            return [
            {
                label:'STATUS',
                status: 'Connected',
                value: 'since',
                class: 'isLive',
                type: null
            },
            {
                label:'RESULT',
                status: this.checkTypeCurrency(this.data.results.result.value),
                value: '?? games',
                class: 'isLive',
                type: null
            },
            {
                label:'TOTAL BET',
                status: this.checkTypeCurrency(this.data.results.totalBet.value),
                value: this.checkTypeCurrency(this.data.results.averageBetPerGame.value)+' avg bet',
                class: 'isLive',
                type: null
            },
            {
                label: 'MONEY Balance',
                status: this.checkTypeCurrency(this.data.moneyBalance.balance.value),
                value: '?? transactions',
                class: 'isLive',
                type: null
            },
            {
                label: 'Marketing Status',
                status: 'VIP',
                value:  'since...',
                class: 'isLive',
                type: null
            },
            {
                label: 'Surveillance Status',
                status: 'AOE',
                value:  'since...',
                class: 'isLive',
                type: null
            }
        ]
        },
        ...mapGetters(['filterDate']),
    },
    mounted(){
        this.getCasino();
        this.getEgm();
    },
    methods: {
        getEgm(){
            axios.get('admin/egms/'+ this.$route.params.egm_id)
                .then(res=>{
                    this.egmData = res.data;         
                });
        },

        getCasino(){
            axios.get('system/casinos/'+ this.$route.params.casino_id)
                .then(res=>{
                    this.casinoData = res.data;         
                });
        },

        getData(){
           
            var self = this;            
            function results(){
                return axios.get('operational/casinos/'+self.$route.params.casino_id+'/egms/'+self.$route.params.egm_id+'/cResults?'+self.filterDate);
            };
          
            function moneyBalance(){
                 return axios.get('operational/casinos/'+self.$route.params.casino_id+'/egms/'+self.$route.params.egm_id+'/cMoneyBalance?'+self.filterDate);
            };

            Promise.all([results(), moneyBalance()])
                    .then(res=>{
                        this.data = {};
                        this.data.results = res[0].data;
                        this.data.moneyBalance = res[1].data;
                    });
        },
    }
}
</script>
