<template>  
    <v-row>
        <v-col xs='12' sm='12' md='12' lg='12' xl='12'>
            <div class="content">
                <div class="tab zones">
                    <v-row>
                        <v-col col="12">
                            <div class="card card-teal" style='margin-top:60px'  v-if='data' data-aos="fade">
                                <div class="card_header">
                                    <span class="float-left">Zones</span>
                                    <span></span>
                                </div>
                                <crud-table class="gen_table" :data='data' :fields='fields'></crud-table>
                            </div>
                        </v-col>
                    </v-row>                     
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>

import CrudTable from '@/components/tables/CrudTable.vue'


export default {
    components:{
        'crud-table':CrudTable
    },
    props: ['filterDate'],
    data(){
        return {
            data:null,            
            fields:[
                {
                    label: 'Zone',
                    getValue: item => item.casinoZone,
                    getLink: item => '/panel/operational/casino/'+this.$route.params.casino_id+'/zone/' + item.casinoZoneId,
                    type: 'text',
                    key: 'zone'
                },

                {
                    label: 'Total Bet',
                    getValue: item => this.checkTypeCurrency(item.totalBet), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalBet'
                },
                {
                    label: 'Total Win',
                    getValue: item => this.checkTypeCurrency(item.totalWin), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalWin'
                },
                {
                    label: 'Result',
                    getValue: item => this.checkTypeCurrency(item.result), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'result'
                },
                {
                    label: 'Theoretical Result',
                    getValue: item => this.checkTypeCurrency(item.theoreticalResult), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'theoreticalResult'
                },
                {
                    label: 'Actual RTP',
                    getValue: item => this.checkTypeCurrency(item.actualRTP), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'actualRTP'
                },
                {
                    label: 'Theoretical RTP',
                    getValue: item => this.checkTypeCurrency(item.theoreticalRTP), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'theoreticalRTP'
                },
                {
                    label: 'Games',
                    getValue: item => this.checkTypeCurrency(item.gamesPlayed), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'gamesPlayed'
                },
                {
                    label: 'Total Drop',
                    getValue: item => this.checkTypeCurrency(item.totalDrop), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalDrop'
                },
                {
                    label: 'Total PayOut',
                    getValue: item => this.checkTypeCurrency(item.totalCancelledCredits), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'totalCancelledCredits'
                },
                {
                    label: 'Max Credits',
                    getValue: item => this.checkTypeCurrency(item.maxCredits), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'maxCredits'
                },
                {
                    label: 'No of Sessions',
                    getValue: item => this.checkTypeCurrency(item.numberOfSessions), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfSessions'
                },
                {
                    label: 'No of Players',
                    getValue: item => this.checkTypeCurrency(item.numberOfPlayers), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfPlayers'
                },
                {
                    label: 'No of Opened Sessions',
                    getValue: item => this.checkTypeCurrency(item.numberOfOpenedSessions), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfOpenedSessions'
                },
                {
                    label: 'No of Closed Sessions',
                    getValue: item => this.checkTypeCurrency(item.numberOfClosedSessions), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'numberOfClosedSessions'
                },
                {
                    label: 'Duration',
                    getValue: item => this.checkTypeDuration(item.duration), 
                   getHeaderClass: item => 'text_right',
                    getClass: item => 'text_right',
                    type: 'text',
                    key: 'duration'
                },

               
            ],
        }
    },
     watch: {
        filterDate: function(oldDate, newDate){
            this.getCasinoZones();
        }
    },
    mounted(){
        this.getCasinoZones();
    },
    methods: {
        getCasinoZones(){
             axios.get('operational/casinos/'+this.$route.params.casino_id+'/cZones?'+this.filterDate)
                .then(res=>{
                    this.data = res.data
                })
        }       
    }
}
</script>